import {
  DashboardOutlined,
  AppstoreOutlined,
  FileTextOutlined,
  PieChartOutlined,
  EnvironmentOutlined,
  AntDesignOutlined,
  SafetyOutlined,
  StopOutlined,
  DotChartOutlined,
  MailOutlined,
  MessageOutlined,
  CalendarOutlined,
  BulbOutlined,
  InfoCircleOutlined,
  CompassOutlined,
  LayoutOutlined,
  DesktopOutlined,
  FileDoneOutlined,
  CommentOutlined,
  RobotOutlined,
  PlusCircleOutlined,
  FundOutlined,
  ShoppingCartOutlined,
  BookOutlined,
  FileUnknownOutlined,
  ProfileOutlined,
} from "@ant-design/icons";

import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import VideoCameraBackOutlinedIcon from "@mui/icons-material/VideoCameraBackOutlined";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";

import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from "configs/AppConfig";

const dashBoardNavTree = [
  {
    key: "dashboards",
    path: `${APP_PREFIX_PATH}/dashboards`,
    title: "sidenav.dashboard",
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "dashboards-default",
        path: `${APP_PREFIX_PATH}/dashboards/default`,
        title: "sidenav.dashboard.default",
        icon: DashboardOutlined,
        breadcrumb: false,
        submenu: [],
      },
      /*  {
        key: "dashboards-analytic",
        path: `${APP_PREFIX_PATH}/dashboards/analytic`,
        title: "sidenav.dashboard.analytic",
        icon: DotChartOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "dashboards-sales",
        path: `${APP_PREFIX_PATH}/dashboards/sales`,
        title: "sidenav.dashboard.sales",
        icon: FundOutlined,
        breadcrumb: false,
        submenu: [],
      }, */
    ],
  },
];

const appsNavTree = [
  {
    key: "apps",
    path: `${APP_PREFIX_PATH}/apps`,
    title: "sidenav.apps",
    icon: AppstoreOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "apps-calendar",
        path: `${APP_PREFIX_PATH}/apps/calendar`,
        title: "sidenav.apps.calendar",
        icon: CalendarOutlined,
        breadcrumb: true,
        submenu: [],
      },
    ],
  },
];

const icon = {
  profile: () => (
    <span role="img" className="anticon">
      <PersonOutlineOutlinedIcon />
    </span>
  ),
  users: () => (
    <span role="img" className="anticon">
      <PeopleAltOutlinedIcon />
    </span>
  ),
  snapshots: () => (
    <span role="img" className="anticon">
      <ImageOutlinedIcon />
    </span>
  ),
  videos: () => (
    <span role="img" className="anticon">
      <VideoCameraBackOutlinedIcon />
    </span>
  ),
  timeline: () => (
    <span role="img" className="anticon">
      <TimelineOutlinedIcon />
    </span>
  ),
  report: () => (
    <span role="img" className="anticon">
      <AssessmentOutlinedIcon />
    </span>
  ),
  attendance: () => (
    <span role="img" className="anticon">
      <CalendarMonthOutlinedIcon />
    </span>
  ),
  setting: () => (
    <span role="img" className="anticon">
      <SettingsOutlinedIcon />
    </span>
  ),
};

const extraNavTree = [
  {
    key: "pages",
    path: `${APP_PREFIX_PATH}/pages`,
    title: "sidenav.pages",
    icon: PlusCircleOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: "extra-pages-profile",
        path: `${APP_PREFIX_PATH}/pages/profile`,
        title: "sidenav.pages.profile",
        icon: icon.profile,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "extra-pages-users",
        path: `${APP_PREFIX_PATH}/pages/users`,
        title: "sidenav.pages.users",
        icon: icon.users,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "extra-pages-snap",
        path: `${APP_PREFIX_PATH}/pages/snapshot`,
        title: "sidenav.pages.snapshots",
        icon: icon.snapshots,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "extra-pages-video",
        path: `${APP_PREFIX_PATH}/pages/video`,
        title: "sidenav.pages.videos",
        icon: icon.videos,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "extra-pages-timeline",
        path: `${APP_PREFIX_PATH}/pages/timeline`,
        title: "sidenav.pages.timeline",
        icon: icon.timeline,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "extra-pages-report",
        path: `${APP_PREFIX_PATH}/pages/report`,
        title: "sidenav.pages.report",
        icon: icon.report,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "extra-pages-user-report",
        path: `${APP_PREFIX_PATH}/pages/user-report`,
        title: "sidenav.pages.userreport",
        icon: icon.report,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "extra-pages-attendance",
        path: `${APP_PREFIX_PATH}/pages/attendance`,
        title: "sidenav.pages.attendance",
        icon: icon.attendance,
        breadcrumb: true,
        submenu: [],
      },
      /* {
        key: "extra-pages-invoice",
        path: `${APP_PREFIX_PATH}/pages/invoice`,
        title: "sidenav.pages.invoice",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "extra-pages-pricing",
        path: `${APP_PREFIX_PATH}/pages/pricing`,
        title: "sidenav.pages.pricing",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "extra-pages-faq",
        path: `${APP_PREFIX_PATH}/pages/faq`,
        title: "sidenav.pages.faq",
        icon: "",
        breadcrumb: false,
        submenu: [],
      }, */
      {
        key: "extra-pages-setting",
        path: `${APP_PREFIX_PATH}/pages/setting`,
        title: "sidenav.pages.setting",
        icon: icon.setting,
        breadcrumb: true,
        submenu: [],
      },
    ],
  },
];

const navigationConfig = [
  ...dashBoardNavTree,
  /*  ...appsNavTree, */ ...extraNavTree,
];

export default navigationConfig;
