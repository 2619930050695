import axios from "axios";
import { error } from "components/shared-components/Toast/Toast";
import { AUTH_TOKEN } from "redux/constants/Auth";

const API = axios.create({ baseURL: process.env.REACT_APP_API });

API.interceptors.request.use((req) => {
  if (localStorage.getItem(AUTH_TOKEN)) {
    req.headers.Authorization = `Bearer ${localStorage.getItem(AUTH_TOKEN)}`;
  }
  return req;
});

export const call = (callback) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await callback;
      if (data.success) {
        delete data.success;
        resolve(data);
      } else {
        if (data.message === "Invalid token") {
          error("Invalid token, please login again");
        }
        delete data.success;
        reject(data);
      }
    } catch (err) {
      reject("Something went wrong");
    }
  });
};

// Authentication

export const login = (data) => API.post("/auth/admin/login", data);
export const verifyToken = () => API.get("/auth/admin/verify");
export const changePassword = (data) =>
  API.post("/auth/admin/changepassword", data);
export const resetPassword = (data) =>
  API.post("/auth/user/resetpassword", data);

export const users = () => API.get("/user/all");
export const getUsers = () => API.get("/user/list");
export const userInfo = (data) => API.get("/user/info/" + data.id);
export const userAdd = (data) => API.post("/user/add", data);
export const userUpdate = (data) => API.put("/user/edit", data);

export const getTimelines = (data) => API.post("/timeline/list", data);

export const getAttendance = (data) => API.post("/attendance/list", data);
export const getUserTimesheets = (data) =>
  API.post("/report/user-timesheet", data);
export const getTimesheets = (data) => API.post("/report/timesheet", data);
export const getTimesheetsAll = (data) =>
  API.post("/report/timesheet/all", data);
export const getDurationslots = (data) =>
  API.post("/report/durationslots", data);
export const getInactiveslots = (data) =>
  API.post("/report/inactiveslots", data);
export const getSnaps = (data) => API.post("/report/snaps", data);
export const getVideos = (data) => API.post("/report/videos", data);

export const monitoringTitleList = () =>
  API.get("/setting/monitoring/titleList");
export const monitoringList = () => API.get("/setting/monitoring/List");
export const monitoringAdd = (data) => API.post("/setting/monitoring", data);
export const monitoringUpdate = (data) =>
  API.put("/setting/monitoring/" + data.id, data);
export const monitoringDelete = (data) =>
  API.delete("/setting/monitoring/" + data.id);

export const deleteFiles = (data) => API.post("/upload/delete-files", data);
